<template>
  <!-- <div class="container"> -->
    <div class="row justify-content-center">
      <div class="col-12 d-flex size-box">
        <div class="">
          <div class="px-4 position-absolute titulo-graph">
            <div class="px-4 mb-5">
              <p class="titulo-pag">Mapa</p>
              <h1 class="mt-3">Mapa de conexões</h1>
              <span>Visualize as conexões existentes</span>
            </div>
          </div>
          <v-network-graph :nodes="nodes" :edges="edges" :layouts="layouts" :configs="configs" :event-handlers="eventHandlers"  v-if="opacity === false">
            <template #edge-label="{ edge, ...slotProps }">
              <v-edge-label :text="edge.label" align="center" vertical-align="above" v-bind="slotProps" />
            </template>
          </v-network-graph>
          <div v-if="opacity === true" class="carregando">
            <div class="spin-load" v-if="opacity === true">
              <div class="spinner-grow text-primary spn" role="status">
              </div>
            </div>
            <p>carregando grafo</p>
          </div>
          <template v-if="this.parceriaSelected !== undefined">
          <div class="box-user" v-show="showBoxParceria === true">
            <div class="box-header">
              <div class="row d-flex justify-content-end">
                <div class="col-1"><img src="../../assets/icon-close.png" class="icon-close" alt="Opções" @click="showBoxParceria = false"></div>
              </div>
              <div class="row">
                <div class="col-8">
                  <span>De {{new Date(this.parceriaSelecionada.data_inicio).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}} até {{new Date(this.parceriaSelecionada.data_conclusao).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}}</span><br>
                  <h3>{{this.parceriaSelecionada.nome_da_acao }}</h3>
                </div>
                <div class="col-3"><img src="../../assets/logo-letra-branca-fundo-azul.png" class="img-box" alt="Logo Unibanco"></div>
              </div>
            </div>
            <ul class="atores-vinculados-box">
              <li>
                <div class="row cursor-pointer">
                  <div class="d-flex">
                    <div class="col-10 titulo-item-box">Atores vinculados</div>
                    <div class="col-2 box-text-right"></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <ul class="itens-box texto-item-box">
                      <li v-for="user of this.atoresParceria" :key="user.id"><img class="icon-box" :src="user.foto_perfil">{{user.nome_do_ator}}</li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          </template>

          <template v-if="this.planoSelected !== undefined">
          <div class="box-user" v-show="showBoxPlano === true">
            <div class="box-header">
              <div class="row d-flex justify-content-end">
                <div class="col-1"><img src="../../assets/icon-close.png" class="icon-close" alt="Opções" @click="showBoxPlano = false"></div>
              </div>
              <div class="row">
                <div class="col-8">
                  <span>Início {{new Date(this.planoSelected.data_inicio).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}}</span><br>
                  <h3>{{this.planoSelected.proposta}}</h3>
                </div>
                <div class="col-3"><img src="../../assets/logo-letra-branca-fundo-azul.png" class="img-box" alt="Logo Unibanco"></div>
              </div>
            </div>
            <ul class="atores-vinculados-box">
              <p style="margin-top: 20px;">{{this.planoSelected.desafio}}</p>
              <li>
                <div class="row cursor-pointer">
                  <div class="d-flex">
                    <div class="col-10 titulo-item-box">Atores vinculados</div>
                    <div class="col-2 box-text-right"></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <ul class="itens-box texto-item-box">
                      <li v-for="user of this.atoresPlano" :key="user.id"><img class="icon-box" :src="user.foto_perfil">{{user.nome_do_ator}}</li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          </template>
          <template v-if="this.userSelected !== undefined">
            <div class="box-user" v-show="showBox === true">
              <div class="box-header">
                <div class="row d-flex justify-content-end">
                  <div class="col-1"><img src="../../assets/icon-close.png" class="icon-close" alt="Opções" @click="showBox = false"></div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <span>{{ this.userSelected.tipo_do_ator !== undefined ? (this.userSelected.tipo_do_ator === 'fisico' ? 'Pessoa Física' : 'Pessoa Jurídica') : '' }}</span>
                    <h3>{{this.userSelected.nome_do_ator}}</h3>
                  </div>
                  <div class="col-3"><img :src="this.userSelected.foto_perfil" class="img-box" alt="Foto de perfil"></div>
                </div>
              </div>
              <div class="box-content">
                <div class="row">
                  <ul>
                    <li>
                      <div class="row cursor-pointer" :class="{activeBar: showInfoPessoal === true}">
                        <div class="d-flex" @click="showInfoPessoal === false ? showInfoPessoal = true : showInfoPessoal = false">
                          <div class="col-10 titulo-item-box">Informações pessoais</div>
                          <div class="col-2 box-text-right" v-show="showInfoPessoal === true"><img src="../../assets/fi-rr-menas-small.svg"></div>
                          <div class="col-2 box-text-right" v-show="showInfoPessoal === false"><img src="../../assets/fi-rr-cross-small.svg"></div>
                        </div>
                      </div>
                      <div class="row" v-show="showInfoPessoal === true">
                        <div class="col-12">
                          <ul class="itens-box texto-item-box">
                            <li v-if="this.userSelected.telefone">Fone: {{this.userSelected.telefone}}</li>
                            <li v-if="this.userSelected.cpf_cnpj">CPF: {{this.userSelected.cpf_cnpj}}</li>
                            <li v-if="this.userSelected.email">Email: {{this.userSelected.email}}</li>
                            <li v-if="this.userSelected.url_facebook">Facebook: <a :href="this.userSelected.url_facebook" target="_blank">Clique aqui</a></li>
                            <li v-if="this.userSelected.url_linkedin">Linkedin: <a :href="this.userSelected.url_linkedin" target="_blank">Clique aqui</a></li>
                            <li v-if="this.userSelected.url_outra_midia">Outra: <a :href="this.userSelected.url_outra_midia" target="_blank">Clique aqui</a></li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row cursor-pointer" :class="{activeBar: showInfoProf === true}">
                        <div class="d-flex" @click="showInfoProf === false ? showInfoProf = true : showInfoProf = false">
                          <div class="col-10 titulo-item-box">Informações profissionais</div>
                          <div class="col-2 box-text-right" v-show="showInfoProf === true"><img src="../../assets/fi-rr-menas-small.svg"></div>
                          <div class="col-2 box-text-right" v-show="showInfoProf === false"><img src="../../assets/fi-rr-cross-small.svg"></div>
                        </div>
                      </div>
                      <div class="row" v-show="showInfoProf === true">
                        <div class="col-12">
                          <ul class="itens-box texto-item-box">
                            <li v-if="this.userSelected.organizacao_atual">Empresa atual: {{this.userSelected.organizacao_atual}}</li>
                            <li v-if="this.userSelected.estado">UF: {{this.userSelected.estado}}</li>
                            <li v-if="this.userSelected.departamento_area">Departamento: {{this.userSelected.departamento_area}}</li>
                            <li v-if="this.userSelected.cargo_pessoa_fisica">Cargo: {{this.userSelected.cargo_pessoa_fisica}}</li>
                            <li v-if="this.userSelected.atribuicoes">Atribuições: {{this.userSelected.atribuicoes}}</li>
                            <li v-if="this.userSelected.campos_de_atuacao">Campos: {{this.userSelected.campos_de_atuacao}}</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row cursor-pointer" :class="{activeBar: showRel === true}">
                        <div class="d-flex" @click="showRel === false ? showRel = true : showRel = false">
                          <div class="col-10 titulo-item-box">Relação</div>
                          <div class="col-2 box-text-right" v-show="showRel === true"><img src="../../assets/fi-rr-menas-small.svg"></div>
                          <div class="col-2 box-text-right" v-show="showRel === false"><img src="../../assets/fi-rr-cross-small.svg"></div>
                        </div>
                      </div>
                      <div class="row" v-show="showRel === true">
                        <div class="col-12">
                          <ul class="itens-box texto-item-box">
                            <li v-if="this.userSelected.relacao_unibanco">Relação com IU: {{this.userSelected.relacao_unibanco}}</li>
                            <li v-if="this.userSelected.relacao_cdg">Relação CDG: {{this.userSelected.relacao_cdg}}</li>
                            <li v-if="this.userSelected.funcao_programa">Função: {{this.userSelected.funcao_programa}}</li>
                            <li v-if="this.userSelected.responsabilidade_no_programa">Responsabilidade: {{this.userSelected.responsabilidade_no_programa}}</li>
                            <li v-if="this.userSelected.grau_de_poder">Grau de poder: {{this.userSelected.grau_de_poder}}</li>
                            <li v-if="this.userSelected.grau_de_interesse">Grau de interesse: {{this.userSelected.grau_de_interesse}}</li>
                            <li v-if="this.userSelected.campos_incidencias_e_articulacoes">Campos atuação: {{this.userSelected.campos_incidencias_e_articulacoes}}</li>
                            <li v-if="this.userSelected.potencialidades">Potencialidades: {{this.userSelected.potencialidades}}</li>
                            <li v-if="this.userSelected.avalicao_de_perfil"> Avaliação: {{this.userSelected.avalicao_de_perfil}}</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row cursor-pointer" :class="{activeBar: showVincul === true}">
                        <div class="d-flex" @click="showVincul === false ? showVincul = true : showVincul = false">
                          <div class="col-10 titulo-item-box">Atores vinculados</div>
                          <div class="col-2 box-text-right" v-show="showVincul === true"><img src="../../assets/fi-rr-menas-small.svg"></div>
                          <div class="col-2 box-text-right" v-show="showVincul === false"><img src="../../assets/fi-rr-cross-small.svg"></div>
                        </div>
                      </div>
                      <div class="row" v-show="showVincul === true">
                        <div class="col-12">
                          <ul class="itens-box texto-item-box">
                            <li v-for="user of this.ligacoes" :key="user.id"><img class="icon-box" :src="user.foto_perfil">{{user.nome_do_ator}}</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row cursor-pointer" :class="{activeBar: showParceria === true}">
                        <div class="d-flex" @click="showParceria === false ? showParceria = true : showParceria = false">
                          <div class="col-10 titulo-item-box">Parcerias</div>
                          <div class="col-2 box-text-right" v-show="showParceria === true"><img src="../../assets/fi-rr-menas-small.svg"></div>
                          <div class="col-2 box-text-right" v-show="showParceria === false"><img src="../../assets/fi-rr-cross-small.svg"></div>
                        </div>
                      </div>
                      <div class="row" v-show="showParceria === true">
                        <div class="col-12">
                          <ul class="itens-box texto-item-box">
                            <li v-for="parceria of this.parceriasDescricao" :key="parceria.id">Parceria: {{parceria.nome_da_acao}}</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row cursor-pointer" :class="{activeBar: showPlano === true}">
                        <div class="d-flex" @click="showPlano === false ? showPlano = true : showPlano = false">
                          <div class="col-10 titulo-item-box">Planos de Ação</div>
                          <div class="col-2 box-text-right" v-show="showPlano === true"><img src="../../assets/fi-rr-menas-small.svg"></div>
                          <div class="col-2 box-text-right" v-show="showPlano === false"><img src="../../assets/fi-rr-cross-small.svg"></div>
                        </div>
                      </div>
                      <div class="row" v-show="showPlano === true">
                        <div class="col-12">
                          <ul class="itens-box texto-item-box">
                            <li v-for="plano of this.planosDescricao" :key="plano.id">Desafio: {{plano.desafio}}</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>
<script>
/* eslint-disable */
import * as VNetworkGraph from 'v-network-graph';

import {
  ForceLayout
  // ForceNodeDatum,
  // ForceEdgeDatum
} from 'v-network-graph/lib/force-layout';
import {
  MapaDeAtoresRepository, ParceriasRepository, PlanosDeAcaoRepository
// ParceriasRepository
} from '../../libs/repositories';

export default {
  name: 'MapaDeConexoes',
  data () {
    const eventHandlers = {
      'node:click': ({ node }) => {
        this.nodeSelected = node;
      },
      // 'edge:pointerover': ({ edge }) => {
      //   let edg = 'edge'+edge;
      //   let item = document.querySelector(edg);
      //   console.log(item);
      // }
    };

    return {
      getLigacao: null,
      collectionData: null,
      nodeSelected: '',
      nodes: [],
      edges: [],
      eventHandlers,
      userSelected: '',
      configs: {},
      showBox: false,
      showInfoPessoal: false,
      showInfoProf: false,
      showRel: false,
      showVincul: false,
      showPlano: false,
      showParceria: false,
      getParcerias: null,
      userSelectedLigacao: null,
      ligacoes: [],
      getAllParcerias: null,
      getLigacaoPlanoDeAcao: null,
      getPlanosDeAcao: null,
      userSelectedLigacaoParceria: null,
      parceriasUserSelected: null,
      parceriasDescricao: [],
      planosUserSelected: [],
      planosDescricao: null,
      showBoxParceria: false,
      showBoxPlano: false,
      parceriaSelected: [],
      planoSelected: [],
      atoresParceria: [],
      atoresPlano: [],
      parceriaSelecionada:[],
      getNomeLigacao: null,
      opacity: true,
    };
  },
  methods: {
    //comentario para forçar dev
    //Exibe as janelas modais referentes a cada tipo de ator
    selectUser () {      
      if(this.nodeSelected.indexOf('node') === 0){
        this.showBox = true;
        this.showBoxParceria = false;
        this.showBoxPlano = false;

        this.nodeSelected = this.nodeSelected.replace('node', '');
        this.userSelected = this.collectionData.find(user => user.id === parseInt(this.nodeSelected));
        this.userSelectedLigacao = this.getLigacao.filter(user => user.de_ator_id === parseInt(this.nodeSelected));
        this.userSelectedLigacao = this.userSelectedLigacao.concat(this.getLigacao.filter(user => user.para_ator_id === parseInt(this.nodeSelected)));
        

        // Filtrar todos os itens do array que não são o clicado:
        const filterDe = this.userSelectedLigacao.filter((element) => {
          return parseInt(element.de_ator_id) !== parseInt(this.nodeSelected);
        });
        const filterPara = this.userSelectedLigacao.filter((element) => {
          return parseInt(element.para_ator_id) !== parseInt(this.nodeSelected);
        });
        this.ligacoes = [];
        filterDe.forEach(filter => {
          this.ligacoes = this.ligacoes.concat(this.collectionData.find(element => element.id === parseInt(filter.de_ator_id)));
        });
        filterPara.forEach(filter => {
          this.ligacoes = this.ligacoes.concat(this.collectionData.find(element => element.id === parseInt(filter.para_ator_id)));
        });
      } else if(this.nodeSelected.indexOf('parceria') === 0){
        this.showBox = false;
        this.showBoxParceria = true;
        this.showBoxPlano = false;


        this.nodeSelected = this.nodeSelected.replace('parceria', '');
        this.parceriaSelected = this.getAllParcerias.find(parceria => parceria.parceria_id === parseInt(this.nodeSelected));
        this.parceriaSelecionada = this.getParcerias.find(parceria => parceria.id === parseInt(this.nodeSelected));

        // Filtra todos os usuarios que fazem parte da parceria para exibir no box da parceria
        const envolvidosParceria = this.getAllParcerias.filter(parceria => parceria.parceria_id === parseInt(this.nodeSelected));
        this.atoresParceria = [];
       
        const usersParceriarSelected = envolvidosParceria.filter(envolvido => {
            this.collectionData.filter(atores => {
              if (parseInt(atores.id) === parseInt(envolvido.ator_id)) {
                this.atoresParceria.push(atores);
              }
            });
        })
      } else if(this.nodeSelected.indexOf('plano') === 0){
        this.showBox = false;
        this.showBoxParceria = false;
        this.showBoxPlano = true;

        this.nodeSelected = this.nodeSelected.replace('plano', '');
        this.planoSelected = this.getPlanosDeAcao.find(plano => plano.id === parseInt(this.nodeSelected));

        // Filtra todos os usuarios que fazem parte da parceria para exibir no box do plano
        const envolvidosPlano = this.getLigacaoPlanoDeAcao.filter(plano => plano.plano_de_acao_id === parseInt(this.nodeSelected));
        this.atoresPlano = [];
        envolvidosPlano.filter(envolvido => {
            this.collectionData.filter(atores => {
              if (parseInt(atores.id) === parseInt(envolvido.ator_id)) {
                this.atoresPlano.push(atores);
              }
            });
        });
      }

      // Filtra todas as parcerias que o nó faz parte para exibir no box do ator
      this.userSelectedLigacaoParceria = this.getAllParcerias.filter(parceria => parceria.ator_id === parseInt(this.nodeSelected));
      this.parceriasDescricao = [];
      this.parceriasUserSelected = this.userSelectedLigacaoParceria.filter(element => {
        this.getParcerias.filter(parceria => {
          if (parseInt(parceria.id) === parseInt(element.parceria_id)) {
            this.parceriasDescricao.push(parceria);
          }
        });
      });
      

      // Filtra todos os planos que o nó faz parte para exibir no box do ator
      this.userSelectedLigacaoParceria = this.getLigacaoPlanoDeAcao.filter(plano => plano.ator_id === parseInt(this.nodeSelected));
      this.planosDescricao = [];
      this.planosUserSelected = this.userSelectedLigacaoParceria.filter(element => {
        this.getPlanosDeAcao.filter(plano => {
          if (parseInt(plano.id) === parseInt(element.plano_de_acao_id)) {
            this.planosDescricao.push(plano);
          }
        });
      });
    },
    configsNetwork(){
      this.opacity = false;
      this.configs = VNetworkGraph.defineConfigs({
        view: {
          layoutHandler: new ForceLayout({
            positionFixedByDrag: false,
            positionFixedByClickWithAltKey: true,
          }),
          scalingObjects: true,
          minZoomLevel: 0.1,
          maxZoomLevel: 16,
        },
        node: {
          selectable: true,
          normal: {
            type: 'circle',
            // radius: node => node.size, // Use the value of each node object
            color: node => node.color,
            radius: node => node.size
          },
          hover: {
            radius: node => node.size + 2,
            color: node => node.color
          },
          // focus: {
          //   color: 'black'
          // }
        },
        edge: {
          label: {
            fontSize: 0,
          },
          normal: {
            color: e => e.color,
            width: 2
            // width: edge => edge.width, // Use the value of each edge object
            // color: edge => edge.color,
          }
        }
      });
    }
  },
  async created () {
    this.collectionData = await MapaDeAtoresRepository.getAll();
    this.getParcerias = await ParceriasRepository.getAll();
    this.getPlanosDeAcao = await MapaDeAtoresRepository.getAllPlanos();
    this.collectionData = this.collectionData.concat(this.getParcerias).concat(this.getPlanosDeAcao);
    this.getLigacao = await MapaDeAtoresRepository.getLigacoes();
    this.getNomeLigacao = await MapaDeAtoresRepository.getNomeLigacoes();

    this.getAllParcerias = await MapaDeAtoresRepository.getLigacaoParcerias();
    this.getLigacaoPlanoDeAcao = await MapaDeAtoresRepository.getLigacaoPlanoDeAcao();

    //console.log('this.getNomeLigacao: ', this.getNomeLigacao);

    this.collectionData.forEach(element => {
      // node1: { name: 'Node 1' },
      if (element.nome_do_ator !== undefined) {
        this.nodes.push({ name: element.nome_do_ator, id: element.id, tipo: 'ator' });
      } else if (element.nome_do_ator === undefined && element.desafio === undefined) {
        this.nodes.push({ name: element.nome_da_acao, id: element.id, tipo: 'parceria' });
      } else if (element.nome_do_ator === undefined && element.nome_da_acao === undefined) {
        this.nodes.push({ name: element.desafio, id: element.id, tipo: 'plano' });
      }
    });

    // Esse reduce faz com que a ID do usuário se torne a key do array a partir do dado de cima.
    this.nodes = this.nodes.reduce((m, o) => {
      let randomColor = Math.floor(Math.random() * 16777215).toString(16);
      // randomColor = '#' + randomColor.toString();
      randomColor = '#0A85CA';
      if (o.tipo === 'ator') {
        m[('node' + o.id).toString()] = { name: o.name, color: randomColor, size: 20 };
      } else if (o.tipo === 'parceria') {
        m[('parceria' + o.id).toString()] = { name: o.name, color: '#C6D92D', size: 30 };
      } else if (o.tipo === 'plano') {
        m[('plano' + o.id).toString()] = { name: o.name, color: '#59AA47', size: 30 };
      }
      return m;
    }, {});
    this.getLigacao.forEach(element => {
      // edge1: { source: 'node1', target: 'node2' }
      this.getNomeLigacao.forEach(nomeLigacao => {
        if(parseInt(element.tipo_id) === parseInt(nomeLigacao.id)){
          this.edges.push({ source: 'node' + element.de_ator_id, target: 'node' + element.para_ator_id, color: '#8DC7E7', label: nomeLigacao.name});
          // this.edges.push({ source: 'node' + element.de_ator_id, target: 'node' + element.para_ator_id, color: '#8DC7E7' });
        }
      });
    });
    this.getAllParcerias.forEach(element => {
      // edge1: { source: 'node1', target: 'node2' }
      // this.edges.push({ source: 'parceria' + element.parceria_id, target: 'node' + element.ator_id, color: '#8DC7E7', label: "Parceria" });
      this.edges.push({ source: 'parceria' + element.parceria_id, target: 'node' + element.ator_id, color: '#8DC7E7' });
    });
    this.getLigacaoPlanoDeAcao.forEach(element => {
      // edge1: { source: 'node1', target: 'node2' }
      // this.edges.push({ source: 'plano' + element.plano_de_acao_id, target: 'node' + element.ator_id, color: '#8DC7E7', label: "Plano de Ação" });
      this.edges.push({ source: 'plano' + element.plano_de_acao_id, target: 'node' + element.ator_id, color: '#8DC7E7' });
    });
    this.configsNetwork();
  },
  watch: {
    nodeSelected: function () {
      this.selectUser();
    }
  }
};
</script>
<style scoped>
  .box-user{
    z-index: 2;
    width: 319px;
    min-height: 413px;
    position: fixed;
    background-color: white;
    -webkit-box-shadow: 0 3px 20px 0 rgba(0,0,0,0.07);
    box-shadow: 0 3px 20px 0 rgba(0,0,0,0.07);
    bottom: 10px;
    right: 30px;
    -webkit-border-radius: 5px 5px;
    border-radius: 5px 5px;
  }
  .img-box{
    width: 71px;
    height: 71px;
    border-radius: 71px;
  }
  .icon-box{
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-right: 10px;
  }
  ul{
    list-style-type: none;
  }
  span{
    font-size: 12px;
  }
  h3{
    font-size: 18px;
    font-weight: bold;
  }
  .box-header{
    -webkit-box-shadow: 0 3px 20px 0 rgba(0,0,0,0.07);
    box-shadow: 0 3px 20px 0 rgba(0,0,0,0.07);
    padding: 15px;
  }
  .box-content{
    padding: 12px;
  }
  .box-content ul{
    list-style: none;
  }
  .box-text-right{
    text-align: right;
    font-weight: bold;
  }
  .itens-box{
    padding-left: 0;
    margin: 5px;
  }
  .itens-box li{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .titulo-item-box{
    font-weight: bold;
    font-size: 14px
  }
  .texto-item-box{
    font-size: 12px;
  }
  .icon-close{
    transform: translate(-5px, -15px);
    cursor: pointer;
  }
  .cursor-pointer{
    cursor: pointer;
    padding: 9px 5px;
  }
  .titulo-graph{
    z-index: 2;
  }
  .size-box{
    height: calc(100vh - 20px);
    padding:0;
  }
  .atores-vinculados-box{
    padding-left: 12px;
  }
  .v-network-graph{
    width: calc(100vw - 460px);
  }
  .carregando{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10;
  }
  .carregando p{
    font-size: 10px;
  }
  .titulo-pag{
    font-size: 14px;
    font-weight:600;
    color: #0A85CA;
  }
  h1{
    font-size: 22px;
    font-weight: bolder;
  }
  h1+span{
    font-size: 14px;
    color: #717171;
  }
  .box-user span{
    color: #A0A1A2;
  }
  .spin-load{
    text-align: center;
  }
  .activeBar{
    background-color: #F5F5F5;
  }
</style>
